import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import loadable from '@loadable/component';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';


import './globals.scss';

const Layout = loadable(() => import('./layout/Layout'));
const MainPage = loadable(() => import('./pages/MainPage'));
const NoPage = loadable(() => import('./pages/404'));
const ThankYou = loadable(() => import('./pages/ThankYou'));
const ImageLP = loadable(() => import('./pages/LeadGen/ImageLP'));
const ContentPage = loadable(() => import('./pages/Content'));


// google analytics and tag manager
const TRACKING_ID = "UA-2171185-13";
ReactGA.initialize(TRACKING_ID);
let gtmIDnum;

if (window.location.href.indexOf("joinbeam") > -1) {
    gtmIDnum = 'GTM-K7SGNK4'
} else { gtmIDnum = 'GTM-WJH797S' }

const tagManagerArgs = {
    gtmId: gtmIDnum
}
TagManager.initialize(tagManagerArgs);

// App
const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className='beam-theme' style={{ display: 'block', position: 'relative', width: '100%', height: '100%', maxWidth: '100%' }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<MainPage/>} />  
                        <Route path="*" element={<NoPage/>} />
                        <Route path="/thank-you" element={<ThankYou />} />
                        {/* Lead Gen LPs */}
                        <Route path="/lead-gen-classic" element={<ImageLP type='classic' header='successful-businesses' quotesType='testimonials-classic-beam' />} />
                        <Route path="/lead-gen-medical" element={<ImageLP type='medical' header='successful-businesses' quotesType='testimonials-medical-beam' />} />
                        <Route path="/lead-gen-fec" element={<ImageLP type='fec' header='loved-by' quotesType='testimonials-fec-beam' />} />
                        <Route path="/lead-gen-education" element={<ImageLP type='education' header='over-15k-locations' quotesType='testimonials-education-beam' />} />
                        <Route path="/lead-gen-churches" element={<ImageLP type='churches' header='loved-by' quotesType='testimonials-churches-beam' />} />
                        <Route path="/lead-gen-gyms" element={<ImageLP type='gyms' header='over-15k-locations' quotesType='testimonials-gyms-beam' />} />
                        <Route path="/lead-gen-hotels" element={<ImageLP type='hotels' header='thousands-clients' quotesType='testimonials-hotels-beam' />} />
                        <Route path="/interactive-floor-projector" element={<ImageLP type='interactive-floor-projector' header='satisfied-clients' quotesType='testimonials-hotels-beam' />} />
                        <Route path="/lead-gen-qsr" element={<ImageLP type='qsr' header='thousands-clients' quotesType='testimonials-qsr-beam' />} />
                        {/* Content LPs */}
                        <Route path='/general-content-page' element={<ContentPage data='general' />} />
                        <Route path='/why-education-centers-everywhere-are-embracing-interactive-technology' element={<ContentPage data='education' />} />
                        <Route path='/how-virtual-gaming-is-transforming-health-centers' element={<ContentPage data='medical' />} />
                        <Route path='/new-trend-for-family-entertainment-centers' element={<ContentPage data='fec' />} />
                        <Route path='/by-adding-this-to-your-church-you-can-increase-kids-attendance' element={<ContentPage data='churches' />} />
                        <Route path='/how-churches-are-incorporating-technology' element={<ContentPage data='churches2' />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;